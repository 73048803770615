import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";

import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import FeaturedContent from "../components/common/FeaturedContent";

import {useDispatch, useSelector} from "react-redux";
import { toast } from "react-toastify";
import {reset, verifyOtp} from "../features/auth/authSlice";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const {isSuccess, isLoading, isError, message} = useSelector((state) => state.auth);
  const {email} = useParams();

  const handleInputChange = (index, value) => {
    const updatedOtp = otp.split("");
    updatedOtp[index] = value;
    setOtp(updatedOtp.join(""));
  };

  useEffect(() => {
    if(isError) {
      toast.error(message || 'Error occurred when verifying the otp')
      dispatch(reset());
    }
  }, [isError, message]);

  useEffect(() => {
    if(isSuccess) {
      navigate(`/reset-password/${email}/${otp}`);
      toast.success('Otp Verified successfully');
      dispatch(reset());
    }
  }, [isSuccess]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await dispatch(verifyOtp({otp, email}))
  };

  return (
    <div className="flex justify-between h-screen w-[100vw] overflow-x-hidden overflow-y-auto">
      <div className="flex-1 justify-center items-center relative hidden lg:flex">
        <FeaturedContent />
      </div>

      <div className="flex-1 flex justify-center items-center">
        <div className="max-w-[84%] md:max-w-[40%] lg:max-w-[60%] flex flex-col items-center justify-center gap-3 md:gap-1 lg:gap-2 xl:gap-4 2xl:gap-8">
          <img
            src="/assets/imgs/logo.png"
            className="w-[120px] h-[60px] md:w-[70px] md:h-[35px] lg:w-[120px] lg:h-[60px] xl:w-[150px] xl:h-[75px] 2xl:w-[182px] 2xl:h-[90px] "
            alt="company-logo"
          />
          <div className="text-center space-y-2 md:space-y-1 xl:space-y-3">
            <h1 className="font-urbanistSemibold text-[20px] md:text-lg lg:text-[20px] xl:text-2xl 2xl:text-4xl">
              Verify OTP
            </h1>
            <p className="font-urbanistMedium text-bodyText leading-tight text-[14px] xl:text-[19px] 2xl:text-xl">
              Please enter the OTP code sent to your provided email address.
            </p>
          </div>

          <form
            className="w-full flex flex-col gap-5 md:gap-2 xl:gap-4 2xl:gap-6"
            onSubmit={handleSubmit}
          >
            <div className="flex gap-4 justify-center">
              {[0, 1, 2, 3].map((index) => (
                <div key={index} className="w-20 md:w-40 md:h-20">
                  <InputField
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength={1}
                    required={true}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    className="text-center font-semibold"
                  />
                </div>
              ))}
            </div>

            <Button
              type="submit"
              variant="filled"
              className="flex justify-center items-center"
            >
              {isLoading ? <div className="loader"></div> : "Continue"}
            </Button>
          </form>

          <div className="mt-3 text-center text-[14px] font-urbanistSemibold 2xl:text-lg md:text-[12px] xl:text-[16px]">
            <p>
              Get back to{" "}
              <Link to="/login" className="text-primary">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
