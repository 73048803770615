import React, {useEffect, useRef} from "react";
import { Navigate, Outlet } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authenticateToken} from "./features/auth/authSlice";

const ProtectedRoute = ({ children }) => {
  const { user, isLoading, isError } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const loadingRef = useRef(false);

  const authenticateUser = async () => {
    if(!isLoading && !loadingRef.current){
      loadingRef.current = true;
      await dispatch(authenticateToken());
      loadingRef.current = false;
    }
  }

  useEffect(() => {
    authenticateUser();
  }, [])

  if (!user || !user?.isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return children || <Outlet />;
};

export default ProtectedRoute;
