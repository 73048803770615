import React, {useState, useEffect, useRef} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";

import InputField from "../components/common/InputField";
import Button from "../components/common/Button";
import FeaturedContent from "../components/common/FeaturedContent";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { reset, resetPassword } from "../features/auth/authSlice";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const {otp, email} = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isErrorRemovingRef = useRef(false);
  const isMessageShowingRef = useRef(false);

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  console.log(user, isLoading, isError, isSuccess, message);

  const showError = async () => {
    if (isError && !isErrorRemovingRef.current) {
      isErrorRemovingRef.current = true;
      toast.error(message);
      await dispatch(reset());
      isErrorRemovingRef.current = false;
    }
  }

  const showSuccess = async () => {
    if (user?.isUpdated && !isMessageShowingRef.current) {
      isMessageShowingRef.current = true;
      toast.success("Password changed successfully!");
      await dispatch(reset());
      navigate("/login");
      isMessageShowingRef.current = false;
    }
  }

  useEffect(() => {
    showError()
  }, [isError, message]);

  useEffect(() => {
    showSuccess();
  }, [isSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      toast.error("Passwords do not match");
    } else {
      const data = {
        password,
        email: email,
        otp: otp,
      };
      await dispatch(resetPassword(data));
    }
  };

  return (
    <div className="flex justify-between h-screen">
      <div className="flex-1 justify-center items-center relative hidden lg:flex">
        <FeaturedContent />
      </div>

      <div className="flex-1 flex justify-center items-center">
        <div className="max-w-[84%] md:max-w-[40%] lg:max-w-[60%] flex flex-col items-center justify-center gap-3 md:gap-1 lg:gap-2 xl:gap-4 2xl:gap-8">
          <img
            src="/assets/imgs/logo.png"
            className="w-[120px] h-[60px] md:w-[70px] md:h-[35px] lg:w-[120px] lg:h-[60px] xl:w-[150px] xl:h-[75px] 2xl:w-[182px] 2xl:h-[90px] "
            alt="company-logo"
          />
          <div className="text-center space-y-2 md:space-y-1 xl:space-y-3">
            <h1 className="font-urbanistSemibold text-[20px] md:text-lg lg:text-[20px] xl:text-2xl 2xl:text-4xl">
              Reset Password
            </h1>
            <p className="font-urbanistMedium text-bodyText leading-tight text-[14px] xl:text-[19px] 2xl:text-xl">
              Set the new password for your account so you can login and access
              all the features.
            </p>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-5 md:gap-2 xl:gap-4 2xl:gap-6"
          >
            <InputField
              label="New password"
              type="password"
              placeholder="Enter your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <InputField
              label="Confirm new password"
              type="password"
              placeholder="Confirm your new password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />

            <Button
              type="submit"
              variant="filled"
              className="flex justify-center items-center"
            >
              {isLoading ? <div className="loader"></div> : "Reset Password"}
            </Button>
          </form>

          <div className="mt-3 text-center text-[14px] font-urbanistSemibold 2xl:text-lg md:text-[12px] xl:text-[16px]">
            <p>
              Get back to{" "}
              <Link to="/login" className="text-primary">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
